<template>
  <div>
    <!-- Row Start -->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Shipping Rate</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="outline-primary" v-b-modal.add-rate-modal
                >Add Rate</b-button
              >
            </div>
          </div>

          <div class="card-body body-fluid">
            <div class="table-responsive">
              <b-table
                class="text-nowrap"
                responsive
                striped
                hover
                :items="ratelist"
                :fields="fields"
                show-empty
              >
                <template #cell(actions)="row">
                  <b-button @click="Edit(row.item.id)" variant="secondary"
                    ><i class="flaticon-edit icon-sm"></i> Edit</b-button
                  >
                  <b-button
                    @click="deleterate(row.item.id)"
                    class="ml-2"
                    variant="danger"
                    size="sm"
                    ><i class="flaticon2-cross icon-sm"></i> Delete</b-button
                  >
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Row End -->
    <!-- Add Modal -->
    <b-modal
      id="add-rate-modal"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addrate"
      title="Add Rate"
      size="xl"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <!-- <div class="col-md-4">
            <b-form-group label="Customer">
              <b-form-select v-model="form.customer_id">
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Select</b-form-select-option
                  >
                </template>
                <b-form-select-option
                  v-for="(item, index) in customers"
                  :key="index"
                  :value="item.id"
                >
                  <span v-if="item.type == 1"
                    >{{ item.first_name }} {{ item.last_name }}</span
                  >
                  <span v-if="item.type == 2">{{ item.business_name }}</span>
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div> -->
          <div class="col-md-4">
            <b-form-group label="Origin">
              <b-form-select
                :options="originDestinationlist"
                value-field="id"
                v-model="form.origin"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Destination">
              <b-form-select
                :options="originDestinationlist"
                value-field="id"
                v-model="form.destination"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Shipment type">
              <b-form-select
                :options="shipmenttypelist"
                value-field="name"
                v-model="form.shipment_type"
                text-field="name"
              >
                <template #first>
                  <b-form-select-option :value="emptyString"
                    >All</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Shipping Mode">
              <b-form-select
                :options="shippingmodelist"
                value-field="name"
                v-model="form.shipping_mode"
                text-field="name"
              >
                <template #first>
                  <b-form-select-option :value="emptyString"
                    >All</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </div>
          <!-- <div class="col-md-4">
            <b-form-group label="Shipping Charge">
              <b-form-select
                :options="shippingchargelist"
                value-field="name"
                v-model="form.shipping_charge"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </div> -->
          <div class="col-md-4">
            <b-form-group label="Weight Scale">
              <b-form-select
                :options="weightscalelist"
                value-field="name"
                v-model="form.weight_scale"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="From">
              <b-form-input
                type="number"
                placeholder="0.5"
                step=".01"
                v-model="form.from"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="To">
              <b-form-input
                type="number"
                placeholder="1.0"
                step=".01"
                v-model="form.to"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Rate">
              <b-form-select
                :options="currency"
                value-field="name"
                v-model="form.rate_currency"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Rate Name">
              <b-form-select
                :options="ratenamelist"
                value-field="name"
                v-model="form.rate_name"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </div>
          <!-- <div class="col-md-4">
            <b-form-group label="Rate">
              <b-form-input
                type="number"
                placeholder="0.00"
                step=".01"
                v-model="form.rate"
              ></b-form-input>
            </b-form-group>
          </div> -->
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      id="edit-rate-modal"
      ref="modal"
      @hidden="resetModal"
      @ok="updaterate"
      title="Edit Rate"
    >
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Name">
              <b-form-input v-model="form.name"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- </form> -->
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      emptyString: "",
      ratelist: [],
      isLoading: false,
      customers: [],
      errors: [],
      fields: [
        { key: "id", label: "Rate #" },
        // {
        //   key: "",
        //   label: "Applies To",
        //   formatter: () => {
        //     return "All";
        //   },
        // },
        {
          key: "origin",
          label: "Origin",
          // formatter: (value) => {
          //   if (
          //     this.originDestinationlist.filter((x) => x.id == value).length > 0
          //   ) {
          //     return this.originDestinationlist.filter((x) => x.id == value)[0]
          //       .name;
          //   } else {
          //     return "";
          //   }
          // },
        },
        {
          key: "destination",
          label: "Destination",
          // formatter: (value) => {
          //   if (
          //     this.originDestinationlist.filter((x) => x.id == value).length > 0
          //   ) {
          //     return this.originDestinationlist.filter((x) => x.id == value)[0]
          //       .name;
          //   } else {
          //     return "";
          //   }
          // },
        },
        {
          key: "from",
          label: "Weight",
          formatter: (value, index, row) => {
            if (row.to > 0) {
              return (
                row.from +
                " " +
                row.weight_scale +
                " - " +
                row.to +
                " " +
                row.weight_scale
              );
            } else {
              return row.from + " " + row.weight_scale;
            }
          },
        },
        {
          key: "shipping_mode",
          label: "Mode",
          formatter: (value) => {
            if (value == "" || value == null) {
              return "All";
            } else {
              return value;
            }
          },
        },
        {
          key: "shipment_type",
          label: "Shipment type",
          formatter: (value) => {
            if (value == "" || value == null) {
              return "All";
            } else {
              return value;
            }
          },
        },
        //   {
        //   key: "shipping_charge",
        //   label: "Rate type"
        // },
        {
          key: "rate_name",
          label: "Rate Name",
        },
        // {
        //   key: "rate",
        //   label: "Rate",
        //   formatter: (value, index, row) => {
        //     return row.rate + " " + row.rate_currency;
        //   },
        // },
        {
          key: "created_at",
          label: "Created At",
          formatter: (value) => {
            return new Date(value).toLocaleString();
          },
        },
        { key: "actions", label: "Actions" },
      ],
      // originDestinationlist: [
      //   { id: 1, name: "IDBTM" },
      //   { id: 2, name: "IDJKT" },
      //   { id: 3, name: "AUPER" },
      //   { id: 4, name: "SGSIN" },
      //   { id: 5, name: "MYMYW" },
      // ],
      originDestinationlist: [],
      // shipmenttypelist: [{ name: "General" }, { name: "Food/Supp/Meds" }],
      // shippingmodelist: [
      //   { name: "Truck" },
      //   { name: "Air" },
      //   // { name: "Sea Express" },
      //   // { name: "Sea Consol" },
      // ],
      shippingchargelist: [{ name: "Per/Range" }, { name: "Per/Each" }],
      // weightscalelist: [
      //   { name: "Kgs" },
      //   //  { name: "M3" }
      // ],
      currency: [{ name: "SGD" }],
      firstweightscale: null,
      ratenamelist: [
        { name: "Freight" },
        { name: "Handling" },
        { name: "GST" },
        { name: "Warehousing" },
        { name: "Consolidating" },
        { name: "Repacking" },
        { name: "Pick Up" },
        { name: "Discount" },
        { name: "Permit" },
        { name: "Paid On Behalf" },
        { name: "Manpower" },
        { name: "Delivery" },
      ],
      form: {
        customer_id: null,
        origin: 1,
        destination: 1,
        shipment_type: "",
        shipping_mode: "",
        shipping_charge: "Per/Range",
        weight_scale: this.firstweightscale,
        from: null,
        to: null,
        rate_currency: "SGD",
        rate_name: "Freight",
        rate: null,
        id: null,
      },
    };
  },
  created() {
    this.getAllRates();
    this.getAllCustomers();
    this.getAllZones();
    this.getAllWeightScales();
    this.getAllShipmentTypes();
    this.getAllShippingModes();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Shipping" },
      { title: "Rate" },
    ]);
  },
  components: {
    Loading,
  },
  methods: {
    Edit(id) {
      this.$router.push({ name: "view-rate", params: { id: id } });
    },
    checkErrors() {
      if (this.form.from) {
        return true;
      }

      this.errors = [];

      if (!this.form.from) {
        this.errors.push("From is required.");
      }
      // if (!this.form.to) {
      //   this.errors.push("To is required.");
      // }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    resetModal() {
      this.form.customer_id = null;
      this.form.origin = 1;
      this.form.destination = 1;
      this.form.shipment_type = "";
      this.form.shipping_mode = "";
      this.form.shipping_charge = "Per/Range";
      this.form.weight_scale = this.firstweightscale;
      this.form.from = null;
      this.form.to = null;
      this.form.rate_currency = "SGD";
      this.form.rate_name = "Freight";
      this.form.rate = null;
    },
    addrate(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.post("shippingrate", this.form)
          .then(() => {
            this.getAllRates();
            this.$nextTick(() => {
              this.$bvModal.hide("add-rate-modal");
              this.makeToastVariant(
                "success",
                "Rate added successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    updaterate(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.update("shippingrate", this.form.id, this.form)
          .then(() => {
            this.getRemarksStatusList();
            this.$nextTick(() => {
              this.$bvModal.hide("edit-rate-modal");
              this.makeToastVariant(
                "info",
                "Rate updated successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    getAllRates() {
      this.isLoading = true;
      ApiService.get("shippingrate")
        .then(({ data }) => {
          
          this.ratelist = data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllCustomers() {
      ApiService.get("getallcustomers")
        .then(({ data }) => {
          
          this.customers = data;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllWeightScales() {
      ApiService.get("weightscale")
        .then(({ data }) => {
          this.weightscalelist = data;
          
          this.firstweightscale = this.weightscalelist.length
            ? this.weightscalelist[0].name
            : null;
          // console.log(this.weightscalelist);
          this.form.weight_scale = this.firstweightscale;
          // console.log(this.form);
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllShipmentTypes() {
      ApiService.get("shipmenttype")
        .then(({ data }) => {
          
          this.shipmenttypelist = data;
          // this.form.shipment_type = this.shipmenttypelist.length
          //   ? this.shipmenttypelist[0].name
          //   : null;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllShippingModes() {
      ApiService.get("shippingmode")
        .then(({ data }) => {
          
          this.shippingmodelist = data;
          // this.form.shipping_mode = this.shippingmodelist.length
          //   ? this.shippingmodelist[0].name
          //   : null;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    ShowEditModal(id) {
      this.isLoading = true;
      ApiService.get("shippingrate", id)
        .then(({ data }) => {
          this.form = data;
          this.$bvModal.show("edit-rate-modal");
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllZones() {
      ApiService.get("shippingzone")
        .then(({ data }) => {
          
          this.originDestinationlist = data;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    deleterate(id) {
      this.isLoading = true;
      ApiService.delete("shippingrate", id)
        .then(() => {
          this.getAllRates();
          this.makeToastVariant(
            "warning",
            "Rate deleted successfully",
            "Success"
          );
        })
        .catch(() => {
          this.isLoading = false;
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
  },
};
</script>
